// 0.3 vamos v3 - 26.6.20
// 0.1 inicio - 8.1.20
// import store from '@/store'

import { clone } from 'quasar'
import axios from 'axios'

export default class PadraoModel {
  recurso = null // /tipos-servicos
  microServico = null // usuario
  formLimpo = {}
  apiAlterada = null // passar no construtor ex OfertaModel
  async getListagem ({ params, acao } = {}) {
    return new Promise((resolve, reject) => {
      if (params) {
        if (params.buscaGrid) {
          let queryStringTratada = ''
          let juncao = ''

          for (const propertyName in params.buscaGrid) {
            if (params.buscaGrid[propertyName]) {
              if (queryStringTratada) juncao = ';'
              queryStringTratada = queryStringTratada + juncao + propertyName + ':' + params.buscaGrid[propertyName]
            }
          }
          params.search = queryStringTratada
        }
        delete params.buscaGrid
      }

      axios.get(process.env.API[this.microServico] + '/' + (this.apiAlterada ?? this.recurso) + (acao ?? '') + (this.include ? `?include=${this.include}` : ''), { params: params }).then((response) => {
        resolve(response.data)
      }).catch((error) => {
        reject({ msg: this.retornoPadraoFormulario(error.response), completo: error })
      })
    })
  }

  async getHashedId (id) {
    console.log(id)
    const time = new Date().getTime()
    console.log(time)
    const hash = this.getHash(time, id)
    console.log(hash)
    const hashedId = await this.getBase64(time + ',' + hash)
    console.log(hashedId)
    return new Promise((resolve, reject) => {
      axios.post(process.env.API[this.microServico] + '/' + this.recurso + hashedId + (this.include ? `?include=${this.include}` : ''), { id }).then((response) => {
        if (response.data.erro === 0) {
          this.form = clone(response.data.dados)
        }
        resolve(response.data)
      }).catch((error) => {
        reject({ msg: this.retornoPadraoFormulario(error.response), completo: error })
      })
    })
  }

  getHash (time, id) {
    let md5 = require('md5')
    return md5('' + time + id)
  }

  getBase64 (str) {
    return btoa(str)
  }

  async getId ({ id }) {
    return new Promise((resolve, reject) => {
      axios.get(process.env.API[this.microServico] + '/' + this.recurso + '/' + id + (this.include ? `?include=${this.include}` : '')).then((response) => {
        if (response.data.erro === 0) {
          this.form = clone(response.data.dados)
        }
        resolve(response.data)
      }).catch((error) => {
        reject({ msg: this.retornoPadraoFormulario(error.response), completo: error })
      })
    })
  }
  async salvar (params = null, termsToReplace = null, token = null) {
    // this.iniciar()
    params = this.limparNull(params)
    const idTratado = this.validarIdParamnsId(params)
    let urlFinal = this.recurso
    if (termsToReplace) {
      for (const propertyName in termsToReplace) {
        urlFinal = urlFinal.replace('{' + propertyName + '}', termsToReplace[propertyName])
        if (this.debug) {
          // console.log('busquei', '{' + propertyName + '}')
          // console.log('troquei por', termsToReplace[propertyName])
        }
      }
    }
    if (!idTratado) {
      if (Object.keys(params).includes('upload')) {
        return new Promise((resolve, reject) => {
          axios({
            method: 'post',
            url: process.env.API[this.microServico] + '/' + urlFinal,
            data: params.upload
          }).then((response) => {
            resolve(response.data)
          }).catch((error) => {
            reject({ msg: this.retornoPadraoFormulario(error.response), completo: error })
          })
        })
      }
      return new Promise((resolve, reject) => {
        axios.post(process.env.API[this.microServico] + '/' + this.recurso + (this.include ? `?include=${this.include}` : ''), params).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({ msg: this.retornoPadraoFormulario(error.response), completo: error })
        })
      })
      // const response = await axios
      //   .post(process.env.API[this.microServico] + 'xxx\\' + urlFinal, params, {
      //   }).catch((err) => {
      //     // codigo criado padrao para erro sem internet
      //     if (err.response === undefined && err.status === undefined) {
      //       err.response = []
      //       err.response.status = 380
      //     }
      //     return err.response
      //   })
      // return response
    } else {
      return new Promise((resolve, reject) => {
        axios.put(process.env.API[this.microServico] + '/' + this.recurso + '/' + idTratado + (this.include ? `?include=${this.include}` : ''), params).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({ msg: this.retornoPadraoFormulario(error.response), completo: error })
        })
      })
    }
  }

  validarIdParamnsId (params) {
    let retorno = false
    if (isNaN(params) === false && params !== null) {
      retorno = params
    }
    // console.log('validando',params.id);
    // console.log('validando',params);
    // console.log('validando',params.id);
    if (params !== null) {
      if (!isNaN(params.id)) {
        retorno = params.id
      }
    }
    return retorno
  }

  limparNull (params) {
    // limpando os null
    for (const propertyName in params) {
      if (params[propertyName] == null) { delete params[propertyName] }
      if (params[propertyName] === 'null') { params[propertyName] = null }
    }
    return params
  }

  async deletar (idTratado = null) {
    if (this.validarIdParamnsId(idTratado)) {
      return new Promise((resolve, reject) => {
        axios.delete(process.env.API[this.microServico] + '/' + this.recurso + '/' + idTratado).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject({ msg: this.retornoPadraoFormulario(error.response), completo: error })
        })
      })
    } else {
      return false
    }
  }

  retornoPadraoFormulario (response, params = { msg: '', titulo: '' }) {
    if (response === undefined) {
      return 'Sem conexão com a internet. Verifique sua conexão!'
    }
    if ([500, 400].indexOf(response.status) !== -1) {
      var sum = Object.values(response.data.dados).reduce(function (prevVal, elem) {
        // return prevVal + '  ' + elem
        return ' ' + elem
      }, 0)
      return sum
    }
    if ([401].indexOf(response.status) !== -1) {
      return 'Faça login ou peça permissão a este recurso'
    }
    if ([404].indexOf(response.status) !== -1) {
      return 'Informação não encontrada'
    }
    if ([200].indexOf(response.status) !== -1) {
      const msg = (response.data.msg) ? response.data.msg : params.msg
      return msg
    }
    return 'Erro não identificado, favor entrar em contato com o suporte! Status: ' + response.status
  }
}
